import {type ReactNode} from 'react'
import {gridItem} from '~/design-system/foundations'
import {
  HgButtonLink,
  type HgButtonLinkProps,
  HgAspectRatio,
  HgTextLink,
  type HgTextLinkProps,
} from '~/design-system/hg/components'

export type HgPromoProductProps = {
  heading: ReactNode
  body?: ReactNode
  promoLink: {
    type: 'button' | 'text'
    linkProps:
      | Omit<HgTextLinkProps, 'variant'>
      | Omit<HgButtonLinkProps, 'variant' | 'size'>
  }
  sixteenNineAsset: React.ReactNode
}
const HgPromoProduct = ({
  heading,
  body,
  promoLink,
  sixteenNineAsset,
}: HgPromoProductProps) => {
  return (
    <div
      className={gridItem({
        size: 'heading',
        class:
          'flex flex-col items-center gap-s7 bg-background-secondary p-s4 md:flex-row md:gap-s4',
      })}
    >
      <div className="flex w-full flex-col gap-s5">
        <div className="flex flex-col gap-s2">
          <p className="text-text-default arcadia-subheading-5">{heading}</p>
          {body && <p className="text-text-subdued arcadia-body-2">{body}</p>}
        </div>
        {promoLink.type === 'button' ? (
          <HgButtonLink
            variant="frosted"
            size="medium"
            {...(promoLink.linkProps as HgButtonLinkProps)}
          />
        ) : (
          <HgTextLink
            className="arcadia-body-2"
            {...(promoLink.linkProps as HgTextLinkProps)}
            variant="underline"
          />
        )}
      </div>
      <HgAspectRatio
        aspectRatio="16:9"
        classNames={{
          assetWrapper: 'rounded-none',
          wrapper: 'w-full md:min-w-[212px] md:max-w-[448px]',
        }}
      >
        {sixteenNineAsset}
      </HgAspectRatio>
    </div>
  )
}

export default HgPromoProduct
