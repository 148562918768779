import {tv, type VariantProps} from 'tailwind-variants'
import {HgButton} from '~/design-system/hg/components'

type HgUiControlsProps = {
  numPages: number
  currentIndex: number
  onForward: () => void
  onBack: () => void
  hideForward?: boolean
  hideBack?: boolean
} & VariantProps<typeof controlWrapper>

const controlWrapper = tv({
  base: 'flex items-center gap-s4',
  variants: {
    topSpacing: {
      s0: 'mt-s0',
      s6: 'mt-s6',
      s9: 'mt-s9',
    },
  },
})

export default function HgUiControls(props: HgUiControlsProps) {
  const {
    numPages,
    currentIndex,
    onForward,
    onBack,
    hideBack,
    hideForward,
    topSpacing,
  } = props

  return (
    <div className={controlWrapper({topSpacing})}>
      <span className="w-40 whitespace-nowrap text-text-default arcadia-ui-1">
        {currentIndex + 1} / {numPages}
      </span>
      <div className="grid grid-cols-2 gap-8">
        {!hideBack && (
          <HgButton
            contentType="icon"
            variant="frosted"
            iconProps={{iconType: 'arrow-left'}}
            onClick={onBack}
            aria-label="Back"
          />
        )}
        {!hideForward && (
          <HgButton
            contentType="icon"
            variant="frosted"
            onClick={onForward}
            iconProps={{iconType: 'arrow-right'}}
            aria-label="Forward"
            className="col-start-2"
          />
        )}
      </div>
    </div>
  )
}
