import {
  Button,
  TooltipContent,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
} from '~/design-system/foundations'
import {HgIcon} from '~/design-system/hg/components'
import {tv, type VariantProps} from 'tailwind-variants'
import React from 'react'

const tooltipText = tv({
  base: 'text-text-on-inverted',
  slots: {
    wrapper:
      'z-40 my-s2 flex origin-[var(--radix-tooltip-content-transform-origin)] animate-[scaleIn_300ms_ease-out] flex-col items-start gap-s1 rounded bg-surface-inverted px-s2 pb-[9px] pt-[7px] text-text-on-inverted shadow-lg',
    title: 'text-left arcadia-heading-10',
    body: '',
  },
  variants: {
    variant: {
      default: {
        body: '',
      },
      rich: {
        body: 'arcadia-body-3',
      },
    },
    size: {
      medium: {},
      small: {},
      xs: {},
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      size: 'medium',
      class: {
        body: 'arcadia-ui-1',
      },
    },
    {
      variant: 'default',
      size: 'small',
      class: {
        body: 'arcadia-ui-2',
      },
    },
    {
      variant: 'default',
      size: 'xs',
      class: {
        body: 'arcadia-ui-3',
        wrapper: 'p-s2',
      },
    },
  ],
})

export type HgTooltipVariants = VariantProps<typeof tooltipText>

export type HgTooltipProps = {
  children?: React.ReactNode
  title?: React.ReactNode
  content: React.ReactNode
  size?: 'medium' | 'small' | 'xs'
  side?: 'top' | 'bottom' | 'left' | 'right'
} & HgTooltipVariants

const defaultTrigger = (
  <Button
    className="relative z-10 ml-s1 inline-flex cursor-help items-center justify-center rounded-full align-[-.0714285705em] text-icon-subdued outline-none transition-colors duration-300 ease-in-out hover:text-icon-default focus-visible:text-icon-default focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-focus"
    aria-label="Information"
  >
    <HgIcon size="small" iconType="info-circle" />
  </Button>
)

function HgTooltip({
  children = defaultTrigger,
  content,
  title,
  side = 'top',
  size = 'medium',
  variant = 'default',
}: HgTooltipProps) {
  const {title: titleStyles, body, wrapper} = tooltipText({variant, size})
  return (
    <TooltipProvider delayDuration={100}>
      <TooltipRoot>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          avoidCollisions
          collisionPadding={{top: 72}}
          className={wrapper()}
          side={side}
        >
          {title && <span className={titleStyles()}>{title}</span>}
          <span className={body()}>{content}</span>
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  )
}

export default HgTooltip
