import {type LinkProps as NextLinkProps} from 'next/link'
import React from 'react'
import {tv} from 'tailwind-variants'
import {TableCell, TableHead} from '~/design-system/foundations/Table'
import {
  HgIcon,
  HgTextLink,
  HgTooltip,
  type HgIconType,
} from '~/design-system/hg/components'
import {safeUnreachableCase} from '~/utils/unreachableCase'

type HgLinkProps = Pick<NextLinkProps, 'href'> & {
  analyticsEvent?: {
    id: string
    name: string
  } | null
}

type HgPricingRowTitleCell = {
  type: 'row-title'
  body: React.ReactNode
  link?: HgLinkProps
  tooltip?: string
  caption?: React.ReactNode
}

type HgPricingTableTextCell = {
  type: 'text'
  body: React.ReactNode
  caption?: React.ReactNode
}

type HgPricingTableIconCell = {
  type: 'icon'
  body: HgIconType
}

type HgPricingTableEmptyCell = {
  type: 'empty'
}

type HgPricingPlanHeadCell = {
  type: 'plan'
  body: React.ReactNode
}

type HgPricingSectionHeadCell = {
  type: 'section-title'
  body: React.ReactNode
  icon: HgIconType
  link?: HgLinkProps
}

export type HgPricingTableCellType =
  | HgPricingTableTextCell
  | HgPricingTableIconCell
  | HgPricingTableEmptyCell
  | HgPricingRowTitleCell
  | HgPricingPlanHeadCell
  | HgPricingSectionHeadCell

const hgPricingCellVariants = tv({
  base: 'h-full w-full min-w-[140px] p-0 text-text-default sm:min-w-[156px] md:max-w-[288px] md:p-0 md:pb-s6 md:pt-s3',
  variants: {
    cellType: {
      'row-title':
        'col-span-2 text-left md:col-span-1 [&_a]:decoration-border-subdued',
      'text': 'text-left md:text-center',
      'icon': 'text-left md:text-center',
      'empty': '',
      'plan': 'invisible flex items-center justify-center md:visible ',
      'section-title':
        'flex w-max grow items-center py-s3 text-left md:col-span-1 md:pt-s6',
    },
    oneColumn: {
      true: 'col-span-1',
    },
    isHeader: {
      true: 'py-s3 md:pt-s6',
    },
  },
  compoundVariants: [
    {
      cellType: ['text', 'icon'],
      oneColumn: true,
      class: 'text-right',
    },
  ],
})

const captionVariants = tv({
  base: 'pt-s1 text-text-subdued arcadia-body-3',
})

const RowTitleContent: React.FC<HgPricingRowTitleCell> = cell => {
  return (
    <>
      {cell.link ? (
        <>
          <HgTextLink
            href={cell.link.href}
            analyticsEventName={cell.link?.analyticsEvent?.name}
            variant="underline"
            className="inline"
          >
            {cell.body}
          </HgTextLink>
          {cell.tooltip && <HgTooltip content={cell.tooltip} />}
        </>
      ) : (
        <>
          {cell.body}
          {cell.tooltip && <HgTooltip content={cell.tooltip} />}
        </>
      )}
    </>
  )
}

const SectionTitleContent: React.FC<HgPricingSectionHeadCell> = cell => {
  return (
    <>
      <HgIcon iconType={cell.icon} size="medium" className="mr-s2" />
      {cell.link ? (
        <HgTextLink
          href={cell.link.href}
          analyticsEventName={cell.link.analyticsEvent?.name}
          variant="underline"
        >
          {cell.body}
        </HgTextLink>
      ) : (
        cell.body
      )}
    </>
  )
}

const CellContent: React.FC<{cell: HgPricingTableCellType}> = ({cell}) => {
  switch (cell.type) {
    case 'text':
      return (
        <>
          {cell.body}
          {cell.caption && <div className={captionVariants()}>{cell.caption}</div>}
        </>
      )
    case 'icon':
      return (
        <HgIcon size="medium" iconType={cell.body} className="text-icon-default" />
      )
    case 'row-title':
      return (
        <>
          <RowTitleContent {...cell} />
          {cell.caption && <div className={captionVariants()}>{cell.caption}</div>}
        </>
      )
    case 'section-title':
      return <SectionTitleContent {...cell} />
    case 'plan':
      return <>{cell.body}</>
    case 'empty':
      return null
    default:
      return safeUnreachableCase(cell, null)
  }
}

const HgPricingTableCell: React.FC<{
  cell: HgPricingTableCellType
  oneColumn?: boolean
  isHeader?: boolean
}> = ({cell, oneColumn = false, isHeader = false}) => {
  const cellClass = hgPricingCellVariants({cellType: cell.type, oneColumn, isHeader})
  const content = <CellContent cell={cell} />

  if (isHeader) {
    return <TableHead className={cellClass}>{content}</TableHead>
  }

  return <TableCell className={cellClass}>{content}</TableCell>
}

export default HgPricingTableCell
