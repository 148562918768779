'use client'
import IframeResizer from 'iframe-resizer-react'
import {useIsMobile} from '~/hooks/useMediaQuery'

export default function TestimonialWall() {
  const isMobile = useIsMobile()

  return (
    <div className="mx-auto max-w-[1400px] px-s2 pt-s8" id="testimonial-wall">
      <IframeResizer
        src={`https://embed-v2.testimonial.to/w/hack-week?theme=light&card=base&loadMore=on&initialCount=${
          isMobile ? 10 : 20
        }&hideDate=on&tag=all`}
        style={{width: '1px', minWidth: '100%', minHeight: '800px'}}
      />
    </div>
  )
}
