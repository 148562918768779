import {useThree} from '@react-three/fiber'
import {useRef, useEffect} from 'react'
import * as THREE from 'three'
import {useSphereContext} from './SphereProvider'
import {useIsMobile} from '~/hooks/useMediaQuery'

/**
 * Normalize pointer position to be in the range of -1 to 1
 * */
export const normalizePointerPosition = (
  clientX: number,
  clientY: number,
  container: HTMLDivElement | null
) => {
  if (!container) return {x: 0, y: 0}

  const boundingRect = container.getBoundingClientRect()
  return {
    x: ((clientX - boundingRect.left) / boundingRect.width) * 2 - 1,
    y: -((clientY - boundingRect.top) / boundingRect.height) * 2 + 1,
  }
}

export default function IntersectionUpdater() {
  const {camera, raycaster} = useThree()
  const mouse = useRef(new THREE.Vector2())
  const {
    lastMouseTime,
    pointerVelocity,
    pointsRef,
    lastMousePosition,
    containerRef,
    intersectionPoint,
    pointerDelta,
  } = useSphereContext()
  const isMobile = useIsMobile()

  useEffect(() => {
    const container = containerRef.current
    if (isMobile || !container || !pointsRef.current) return

    // Decrease the precision of the raycaster to interact with points more easily
    raycaster.params.Points.threshold = 0.5

    function handlePointerMove(e: PointerEvent) {
      if (!pointsRef.current || !containerRef.current) return
      const currentTime = Date.now()
      const deltaTime = currentTime - lastMouseTime.current

      const {x, y} = normalizePointerPosition(
        e.clientX,
        e.clientY,
        containerRef.current
      )

      const deltaX = x - lastMousePosition.current.x
      const deltaY = y - lastMousePosition.current.y
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)

      const velocity = deltaTime > 0 ? distance / deltaTime : 0

      pointerVelocity.current = velocity * 100

      // The raycaster sends out a ray from the camera
      // We can update the ray's origin and direction by setting it using the mouse position
      mouse.current.set(x, y)
      raycaster.setFromCamera(mouse.current, camera)

      // We can determine if the cursor is interacting with a point if this ray intersects the point
      const intersections = raycaster.intersectObject(pointsRef.current, false)

      const meetsMovementThreshold = velocity > 0.001 && intersections.length > 0

      intersectionPoint.current = meetsMovementThreshold
        ? intersections[0].point
        : null
      pointerDelta.current = {deltaX, deltaY}

      lastMousePosition.current = {x, y}
      lastMouseTime.current = currentTime
    }

    container.addEventListener('pointermove', handlePointerMove)
    return () => {
      container.removeEventListener('pointermove', handlePointerMove)
    }
  }, [
    isMobile,
    camera,
    containerRef,
    intersectionPoint,
    lastMousePosition,
    lastMouseTime,
    pointerDelta,
    pointerVelocity,
    pointsRef,
    raycaster,
  ])

  return null
}
