/* eslint-disable react/no-unknown-property */
'use client'
import * as THREE from 'three'
import {useEffect, useMemo, useRef} from 'react'
import {extend, useFrame, useThree} from '@react-three/fiber'
import {Points} from '@react-three/drei'
import pointData from '../_data/pointData.json'
import {useSphereContext} from './SphereProvider'
import {CustomShaderMaterial} from './SphereShader'
import useSphereControls from '../_hooks/useSphereControls'

const SPHERE_RADIUS = 20

type UserData = {
  dragRotationVelocity: {
    x: number
    y: number
  }
}

function generateSpherePoints() {
  const positions = new Float32Array(pointData.length * 3)
  const sizes = new Float32Array(pointData.length)

  for (let i = 0; i < pointData.length; i++) {
    positions.set(
      // The point data was generated with a radius of 1, so we need to scale it up to the desired radius
      pointData[i].position.map(point => point * SPHERE_RADIUS),
      i * 3
    )
    sizes.set([pointData[i].scale], i)
  }

  return {positions, sizes}
}

function generateRandomDisplacement(magnitude: number) {
  return (Math.random() - 0.5) * magnitude * 2
}

extend({CustomShaderMaterial})

export default function Sphere() {
  const {size, camera} = useThree()
  const {pointsRef, sphereRef, intersectionPoint, pointerVelocity} =
    useSphereContext()

  const {positions, sizes} = useMemo(() => {
    return generateSpherePoints()
  }, [])

  const tempVector = useRef(new THREE.Vector3())
  const tempCameraPos = useRef(new THREE.Vector3())
  const worldToLocal = useRef(new THREE.Matrix4())
  const displacedPositions = useRef(new Float32Array(positions.length))
  const velocity = useRef(new Float32Array(positions.length))
  const originalPositions = useRef(new Float32Array(positions))

  const {
    sphereVelocityDecay,
    pointColor,
    pointOpacity,
    sphereRotation,
    returnSpeed,
    pointerForce,
    pointsFrictionStrength,
    prefersReducedMotion,
    pointerArea,
  } = useSphereControls()

  // Convert initial rotation from degrees to radians
  const initialRotation = useMemo(() => {
    return new THREE.Euler(
      ...Object.values(sphereRotation).map(THREE.MathUtils.degToRad)
    )
  }, [sphereRotation])

  useEffect(() => {
    if (!pointsRef.current) return
    const geometry = pointsRef.current.geometry
    geometry.setAttribute(
      'displacedPosition',
      new THREE.BufferAttribute(displacedPositions.current, 3)
    )
    geometry.setAttribute('velocity', new THREE.BufferAttribute(velocity.current, 3))

    // These attributes are unused so we can delete them to improve performance slightly
    geometry.deleteAttribute('normal')
    geometry.deleteAttribute('color')
    geometry.deleteAttribute('uv')
  }, [pointsRef])

  useEffect(() => {
    if (!sphereRef.current || !pointsRef.current) return
    // Scale sphere based on resolution
    const sizeScaleFactor = Math.min(Math.max(size.width / size.height, 0.92), 1.35)
    sphereRef.current.scale.setScalar(sizeScaleFactor)

    const referenceWidth = 1920
    const referenceHeight = 1080
    const widthRatio = size.width / referenceWidth
    const heightRatio = size.height / referenceHeight

    // Scale between 35% and 100% of dots shown based on resolution
    const pointScaleFactor = Math.max(
      Math.min(1, Math.min(widthRatio, heightRatio) * 1.25),
      0.35
    )
    const newPointCount = Math.floor(pointData.length * pointScaleFactor)
    pointsRef.current.geometry.setDrawRange(0, newPointCount)

    sphereRef.current.userData = {
      dragRotationVelocity: {x: 0, y: 0},
    } as UserData
  }, [size, sphereRef, pointsRef])

  useFrame((_, delta) => {
    if (!sphereRef.current || !pointsRef.current || prefersReducedMotion) return

    const posArray = pointsRef.current.geometry.attributes.position
      .array as Float32Array
    const displacedArray = pointsRef.current.geometry.attributes.displacedPosition
      .array as Float32Array
    const velocityArray = pointsRef.current.geometry.attributes.velocity
      .array as Float32Array

    const userData = sphereRef.current.userData as UserData
    const {dragRotationVelocity} = userData

    sphereRef.current.rotation.x -= dragRotationVelocity.x
    sphereRef.current.rotation.y += dragRotationVelocity.y

    const geometry = pointsRef.current.geometry

    // Apply dampening to drag sphere rotation
    dragRotationVelocity.x *= 1 - sphereVelocityDecay
    dragRotationVelocity.y *= 1 - sphereVelocityDecay

    tempCameraPos.current.copy(camera.position)
    sphereRef.current.updateMatrixWorld()
    worldToLocal.current.copy(sphereRef.current.matrixWorld).invert()
    tempCameraPos.current.applyMatrix4(worldToLocal.current)

    let hoveredPoint = null
    if (intersectionPoint.current && sphereRef.current) {
      hoveredPoint = intersectionPoint.current.clone()
      // Convert intersection point to local space
      sphereRef.current.updateMatrixWorld()
      worldToLocal.current.copy(sphereRef.current.matrixWorld).invert()
      hoveredPoint.applyMatrix4(worldToLocal.current)
    }

    // Set limit to velocity
    const scaledVelocity = Math.min(pointerVelocity.current, 1.5)

    for (let i = 0; i < pointData.length; i++) {
      const idx = i * 3

      const ox = positions[idx]
      const oy = positions[idx + 1]
      const oz = positions[idx + 2]

      // Calculate current point position including displacement
      tempVector.current.set(
        ox + displacedArray[idx],
        oy + displacedArray[idx + 1],
        oz + displacedArray[idx + 2]
      )

      // Check if point is facing camera (dot product with camera direction)
      const toCameraVector = tempVector.current.clone().sub(tempCameraPos.current)
      const pointNormal = tempVector.current.clone().normalize()
      const dotProduct = toCameraVector.normalize().dot(pointNormal)

      // Only interact with points facing the camera to prevent interaction with points behind the sphere
      const isFacingCamera = dotProduct < 0

      if (hoveredPoint && isFacingCamera) {
        const distance = tempVector.current.distanceTo(hoveredPoint)
        // Larger cursor area for mobile so it's more visible

        if (distance < pointerArea) {
          const force = (1 - distance / pointerArea) * pointerForce * scaledVelocity

          velocityArray[idx] += generateRandomDisplacement(force)
          velocityArray[idx + 1] += generateRandomDisplacement(force)
          velocityArray[idx + 2] += generateRandomDisplacement(force)

          const MAX_Z_DISPLACEMENT = 5
          velocityArray[idx + 2] = Math.min(
            velocityArray[idx + 2],
            MAX_Z_DISPLACEMENT
          )
        }
      }

      // Apply friction to slow down points as they spread out
      velocityArray[idx] *= 1 - pointsFrictionStrength
      velocityArray[idx + 1] *= 1 - pointsFrictionStrength
      velocityArray[idx + 2] *= 1 - pointsFrictionStrength

      // Update point position based on velocity
      displacedArray[idx] += velocityArray[idx] * delta
      displacedArray[idx + 1] += velocityArray[idx + 1] * delta
      displacedArray[idx + 2] += velocityArray[idx + 2] * delta

      // Apply friction to slow down points as they return to their original position
      displacedArray[idx] *= 1 - returnSpeed
      displacedArray[idx + 1] *= 1 - returnSpeed
      displacedArray[idx + 2] *= 1 - returnSpeed

      // Update the visible position based on the original position and displacement since the sphere is continuously rotating
      posArray[idx] = ox + displacedArray[idx]
      posArray[idx + 1] = oy + displacedArray[idx + 1]
      posArray[idx + 2] = oz + displacedArray[idx + 2]
    }

    // Apply constant rotation to points around the sphere
    const rotatedPositions = new Float32Array(originalPositions.current.length)
    for (let i = 0; i < pointData.length; i++) {
      const idx = i * 3
      const {radius, theta, phi} = new THREE.Spherical().setFromCartesianCoords(
        originalPositions.current[idx],
        originalPositions.current[idx + 1],
        originalPositions.current[idx + 2]
      )
      // Update the angular coordinates over time
      const newTheta = theta - delta * 0.015 // Rotate theta

      // Convert updated spherical coordinates back to Cartesian
      const {x, y, z} = new THREE.Vector3().setFromSphericalCoords(
        radius,
        phi,
        newTheta
      )

      // Store rotated positions separately
      rotatedPositions[idx] = x
      rotatedPositions[idx + 1] = y
      rotatedPositions[idx + 2] = z

      // Apply rotation to visible positions while preserving displacement
      posArray[idx] = x + displacedArray[idx]
      posArray[idx + 1] = y + displacedArray[idx + 1]
      posArray[idx + 2] = z + displacedArray[idx + 2]
    }
    // Update the original positions reference for the next frame
    originalPositions.current.set(rotatedPositions)

    // TODO: This is not very performant. Look into using the shader to handle the displacement and rotation
    geometry.attributes.displacedPosition.needsUpdate = true
    geometry.attributes.velocity.needsUpdate = true
  })

  return (
    <group ref={sphereRef} rotation={initialRotation}>
      <Points
        ref={pointsRef}
        positions={positions}
        sizes={sizes}
        limit={pointData.length}
      >
        <customShaderMaterial
          uColor={new THREE.Color(pointColor)}
          uOpacity={pointOpacity}
          transparent
        />
      </Points>
    </group>
  )
}
