import {type ExtractStrict} from 'type-zoo'
import {
  HgButton,
  type HgButtonVariants,
  HgThemeSelector,
} from '~/design-system/hg/components'
import {type Theme} from '../../tokens/colors'
import {cn} from '~/design-system/utils'

export type AnimationControlButtonVariant = ExtractStrict<
  HgButtonVariants['variant'],
  'frosted' | 'elevated'
>

export type HgAnimationButtonsProps = {
  isPlaying: boolean
  isMuted: boolean
  setIsMuted: (isMuted: boolean) => void
  isEnded: boolean
  duration: number
  handlePlayPauseClick: () => void
  showVolumeButton: boolean
  buttonVariant?: AnimationControlButtonVariant
  controlsTheme?: Theme
  loop?: boolean
  circleProgressRef?: React.RefObject<SVGCircleElement>
}

const CIRCLE_LOADER_SIZE = 40
const CIRCLE_LOADER_STROKE_WIDTH = 1
const CIRCLE_LOADER_RADIUS = (CIRCLE_LOADER_SIZE - CIRCLE_LOADER_STROKE_WIDTH) / 2
const CIRCLE_LOADER_CIRCUMFERENCE = CIRCLE_LOADER_RADIUS * 2 * Math.PI
export const RESET_DURATION = 300

export function HgAnimationButtons({
  isPlaying,
  isMuted,
  setIsMuted,
  circleProgressRef,
  duration,
  handlePlayPauseClick,
  showVolumeButton,
  controlsTheme,
  buttonVariant,
  isEnded,
}: HgAnimationButtonsProps) {
  return (
    <HgThemeSelector theme={controlsTheme}>
      <div className="col-start-1 col-end-auto row-start-1 row-end-auto flex items-end p-s3 [&_svg]:text-icon-default">
        <div className="pointer-events-auto grid">
          <HgButton
            variant={buttonVariant}
            contentType="icon"
            className="col-start-1 col-end-auto row-start-1 row-end-auto"
            iconProps={{iconType: isPlaying ? 'pause' : 'play'}}
            onClick={handlePlayPauseClick}
            aria-label={isPlaying ? 'Pause' : 'Play'}
          />
          {!!duration && (
            <svg
              width={CIRCLE_LOADER_SIZE}
              height={CIRCLE_LOADER_SIZE}
              className="pointer-events-none relative col-start-1 col-end-auto row-start-1 row-end-auto rounded-full"
            >
              <circle
                className={cn(
                  // restart animation when video is played again after it ends
                  !isEnded && 'animate-circleLoaderFill',
                  'data-[loop]:animate-circleLoaderFillReset'
                )}
                ref={circleProgressRef}
                style={
                  {
                    'strokeDasharray': `${CIRCLE_LOADER_CIRCUMFERENCE - (buttonVariant === 'elevated' ? 1 : 0)} ${CIRCLE_LOADER_CIRCUMFERENCE - (buttonVariant === 'elevated' ? 2 : 0)}`,
                    'animationPlayState': isPlaying ? 'running' : 'paused',
                    '--playback-duration': `${duration}ms`,
                    '--reset-duration': `${RESET_DURATION}ms`,
                  } as React.CSSProperties
                }
                stroke="currentColor"
                strokeWidth={CIRCLE_LOADER_STROKE_WIDTH}
                fill="transparent"
                r={CIRCLE_LOADER_RADIUS - (buttonVariant === 'elevated' ? 1 : 0)}
                cx={CIRCLE_LOADER_SIZE / 2}
                cy={CIRCLE_LOADER_SIZE / 2}
                transform={`rotate(-90 ${CIRCLE_LOADER_SIZE / 2} ${
                  CIRCLE_LOADER_SIZE / 2
                })`}
              />
            </svg>
          )}
        </div>
        {showVolumeButton && (
          <HgButton
            variant="frosted"
            contentType="icon"
            className="ms-s3"
            iconProps={{iconType: isMuted ? 'audio-off' : 'audio-on'}}
            onClick={() => {
              setIsMuted(!isMuted)
            }}
            aria-label={isMuted ? 'unmute' : 'mute'}
          />
        )}
      </div>
    </HgThemeSelector>
  )
}
