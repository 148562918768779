import {tv, type VariantProps} from 'tailwind-variants'
import {LinkBox, LinkOverlay} from '~/design-system/foundations/LinkBox'
import {type LinkWrapperProps} from '~/design-system/foundations/LinkWrapper'
import {
  HgButtonLink,
  type LabelButtonIconProps,
  type TypeDependentButtonProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import HgAspectRatio from '../HgAspectRatio'

const cardVariants = tv({
  slots: {
    linkBox:
      'h-fit w-full gap-16 rounded-xl bg-surface-default p-16 xl:gap-32 xl:p-32',
    contentContainer: 'flex flex-col justify-between gap-32',
    textContainer: 'grid',
    assetContainer: '',
  },
  variants: {
    orientation: {
      horizontal: {
        linkBox: 'grid sm:grid-cols-2',
        contentContainer: 'min-w-[50%] sm:order-1',
        assetContainer: 'sm:order-2',
      },
      vertical: {
        linkBox: 'flex flex-col',
        contentContainer: 'flex-1 2xl:gap-40',
      },
    },
    topSpacing: {
      s0: {
        linkBox: 'mt-s0',
      },
      s4: {
        linkBox: 'mt-s4',
      },
      s9: {
        linkBox: 'mt-s9',
      },
    },
  },
})

export type HgCardPropsBase = {
  linkProps: LinkWrapperProps
  iconProps?: TypeDependentButtonProps['iconProps']
  asset?: React.ReactNode
  headline: React.ReactNode
  subhead?: React.ReactNode
  buttonText?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export type HgVerticalCardProps = {
  orientation: 'vertical'
} & HgCardPropsBase

export type HgHorizontalCardProps = {
  orientation: 'horizontal'
} & HgCardPropsBase

type HgOrientationDependentProps = HgVerticalCardProps | HgHorizontalCardProps

export type HgCardProps = VariantProps<typeof cardVariants> &
  HgOrientationDependentProps & {
    'data-analytics-event-name'?: string
  }

export default function HgCard(props: HgCardProps) {
  const {
    linkProps,
    iconProps,
    headline,
    buttonText,
    subhead,
    orientation = 'vertical',
    className,
    topSpacing = 's0',
    'data-analytics-event-name': analyticsEventName,
    asset,
  } = props
  const {linkBox, textContainer, contentContainer, assetContainer} = cardVariants({
    orientation,
    topSpacing,
  })
  const buttonProps: TypeDependentButtonProps = buttonText
    ? {
        contentType: 'label',
        iconProps: iconProps ? (iconProps as LabelButtonIconProps) : undefined,
      }
    : {
        'contentType': 'icon',
        'iconProps': {
          iconType: 'arrow-right',
        },
        'aria-label': 'Read More',
      }

  return (
    <LinkBox className={cn(linkBox(), className)}>
      {asset && (
        <HgAspectRatio
          aspectRatio="3:2"
          classNames={{assetWrapper: 'rounded-lg', wrapper: assetContainer()}}
        >
          {asset}
        </HgAspectRatio>
      )}
      <div className={contentContainer()}>
        <div className={textContainer()}>
          <LinkOverlay
            data-analytics-event-name={analyticsEventName}
            {...linkProps}
            aria-hidden
            tabIndex={-1}
          />
          <h2 className="text-text-default arcadia-subheading-5">{headline}</h2>
          {subhead && (
            <p className="mt-8 text-text-subdued arcadia-body-2">{subhead}</p>
          )}
        </div>
        <HgButtonLink
          className="w-fit"
          variant="frosted"
          data-analytics-event-name={analyticsEventName}
          {...buttonProps}
          {...linkProps}
        >
          {buttonText}
        </HgButtonLink>
      </div>
    </LinkBox>
  )
}
