import {
  type HgEmailCaptureVariant,
  type HgEmailCaptureProps,
} from '~/design-system/hg/components'
import {type EmailCaptureFragment} from './_generated/fragments.graphql'
import {getEmailCaptureProps} from '.'
import {type NewsletterList} from '~/mercuryWebBackend/endpoints/NewsletterSubscribe'
import {SingleLineStructuredText} from '~/cms/components/SingleLineStructuredText'
import {getLinkProps} from '~/cms/components/LinkComponent'
import {unreachableCase} from '~/design-system/utils'

export function getEmailCaptureDestinationProps(
  data: EmailCaptureFragment
): HgEmailCaptureProps {
  const {labelText, destination, submitButtonText, variant, secondaryLink} = data

  const destinationProps = (() => {
    switch (destination.__typename) {
      case 'EmailCaptureComponentSignupDestinationRecord':
        return getEmailCaptureProps({
          destination: 'signup',
          signupUrl: (
            destination.signupUrl || 'https://app.mercury.com/redirect/autofill'
          ).trim(),
        })
      case 'EmailCaptureComponentSubscribeDestinationRecord':
        return getEmailCaptureProps({
          destination: 'subscribe',
          list: destination.newsletterList as NewsletterList,
        })
      default:
        return unreachableCase(destination)
    }
  })()

  return {
    submitButtonText,
    successMessage: destination.__typename ===
      'EmailCaptureComponentSubscribeDestinationRecord' && (
      <SingleLineStructuredText data={destination.successMessage} />
    ),
    labelText,
    variant: variant as HgEmailCaptureVariant,
    secondaryLinkProps: getLinkProps(secondaryLink),
    ...destinationProps,
  }
}
