import {grid} from '~/design-system/foundations'

const HgArticleFootnotes = ({text}: {text: React.ReactNode}) => {
  return (
    <div className="w-full pt-s4">
      <div className={grid({className: 'mx-auto'})}>
        <div className="col-span-full grid border-t border-border-frosted md:col-span-8 md:col-start-3 lg:col-start-5">
          <p className="pt-s3 arcadia-body-3">{text}</p>
        </div>
      </div>
    </div>
  )
}

export default HgArticleFootnotes
