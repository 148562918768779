import React from 'react'
import {tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import {
  type HgButtonLinkProps,
  HgAspectRatio,
  HgButtonLink,
} from '~/design-system/hg/components'

export type HgDetailPageHeaderProps = {
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  image?: React.ReactNode
  button?: HgButtonLinkProps
}

const buttonStyles = tv({
  base: 'flex-shrink-0',
  variants: {
    noSubtitleWithImage: {
      true: 'md:self-center',
      false: 'md:self-end',
    },
  },
})

const HgDetailPageHeader: React.FC<HgDetailPageHeaderProps> = ({
  title,
  subtitle,
  image,
  button,
}) => {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        <div
          className={gridItem({
            size: 'max',
            className:
              'flex flex-col gap-y-s4 pt-s8 md:flex-row md:items-start md:justify-between',
          })}
        >
          <div className="flex flex-col gap-y-s4 md:flex-row md:items-center md:gap-x-s4">
            {image && (
              <div className="flex-shrink-0">
                <HgAspectRatio
                  aspectRatio="1:1"
                  classNames={{
                    wrapper:
                      'h-[68px] w-[68px] md:h-[74px] md:w-[74px] xl:h-[96px] xl:w-[96px] 3xl:h-[130px] 3xl:w-[130px] [&>*]:h-full [&>*]:w-full [&_svg]:h-full [&_svg]:w-full',
                  }}
                >
                  {image}
                </HgAspectRatio>
              </div>
            )}
            <div className="flex flex-col">
              <h1 className="text-text-default arcadia-subheading-2">{title}</h1>
              {subtitle && (
                <h1 className="text-text-subdued arcadia-subheading-2">
                  {subtitle}
                </h1>
              )}
            </div>
          </div>
          {button && (
            <div
              className={buttonStyles({noSubtitleWithImage: !subtitle && !!image})}
            >
              <HgButtonLink variant="frosted" {...button} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HgDetailPageHeader
