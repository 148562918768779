import {kebabCase} from 'lodash'
import {tv} from 'tailwind-variants'
import {grid, gridItem} from '~/design-system/foundations'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/design-system/foundations/Accordion'

export type HgAccordionItem = {
  title: React.ReactNode
  text: React.ReactNode
  label: string
}
export type HgAccordionProps = {
  items: HgAccordionItem[]
}

export const hgAccordionVariants = tv({
  slots: {
    accordion: 'arcadia-body-2',
    accordionItem:
      'border-t border-border-subdued last:border-b last:pb-s4 hover:border-t-border-default data-[state=open]:border-t-border-emphasized [&:not(:first-child)]:mt-s4',
    accordionTrigger:
      'transition-color gap-s4 rounded pt-s4 text-left text-text-default outline-2 outline-border-focus hover:text-text-subdued focus-visible:text-text-subdued focus-visible:outline data-[state=open]:text-text-emphasized',
    accordionContent: 'group max-w-[688px] text-text-subdued',
    accordionContentTextContainer:
      'grid transition-[grid-template-rows] duration-300 ease-out group-data-[state=closed]:grid-rows-[0fr] group-data-[state=open]:grid-rows-[1fr]',
    accordionContentText:
      'space-y-[1em] overflow-hidden before:block before:h-s3 [&>span]:block',
  },
})

export default function HgAccordion({items}: HgAccordionProps) {
  const {
    accordion,
    accordionContent,
    accordionContentTextContainer,
    accordionTrigger,
    accordionItem,
    accordionContentText,
  } = hgAccordionVariants()
  return (
    <div className="w-full pt-s9">
      <div className={grid({className: 'mx-auto'})}>
        <Accordion
          type="multiple"
          className={gridItem({size: 'heading', className: accordion()})}
        >
          {items.map((item, index) => {
            const kebabLabel = kebabCase(item.label)
            return (
              <AccordionItem
                className={accordionItem()}
                key={index}
                value={kebabLabel}
                id={kebabLabel}
              >
                <AccordionTrigger className={accordionTrigger()}>
                  {item.title}
                </AccordionTrigger>
                <AccordionContent className={accordionContent()} forceMount>
                  <div className={accordionContentTextContainer()}>
                    <div className={accordionContentText()}>{item.text}</div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}
