import {type ReactNode} from 'react'

export const WYSIWYG_CONTAINER_ID = 'wysiwyg'

const HgArticleWYSIWYGContainer = ({children}: {children: ReactNode}) => {
  return (
    <div
      className="col-span-full grid w-full grid-cols-subgrid lg:row-start-1 [&_ol_+_ul]:my-4 md:[&_ol_+_ul]:my-8 lg:[&_ol_+_ul]:my-12"
      id={WYSIWYG_CONTAINER_ID}
    >
      {children}
    </div>
  )
}

export default HgArticleWYSIWYGContainer
