import {env} from '~/config/env.mjs'
import {isPreview, isProd} from '~/config/nodeEnv'

export function getEnvironment() {
  if (isPreview) return 'preview'

  if (isProd) {
    if (env.NEXT_PUBLIC_BACKEND_SOURCE === 'staging') return 'staging'
    return 'production'
  }

  return 'development'
}
