'use client'
import {useBugsnagErrorBoundary} from '~/utils/Bugsnag/useBugsnagErrorBoundary'
import {ErrorBoundaryFallback} from './_components/ErrorBoundary'
import {neueMontreal} from '~/fonts/NeueMontreal'
import classnames from 'classnames'
import './(legacy)/globals.css'
import {IOVF} from '~/fonts/IOVF'
import type Error from 'next/error'

export default function GlobalError({error}: {error: Error & {digest?: string}}) {
  useBugsnagErrorBoundary({error})

  return (
    <html
      lang="en"
      tabIndex={-1}
      className="box-border h-fit min-h-full w-full text-[16px]"
    >
      <body
        className={classnames(
          neueMontreal.className,
          neueMontreal.variable,
          IOVF.variable,
          'h-fit min-h-full w-full'
        )}
      >
        <ErrorBoundaryFallback />
      </body>
    </html>
  )
}
