'use client'

import React, {useState, useCallback, useId} from 'react'
import {Button} from '~/design-system/foundations'
import {HgTagGroup, type HgTagProps} from '../../HgTag'
import {textLink} from '../../HgTextLink'

const HgDetailTagGroup: React.FC<{tags: HgTagProps[]; title: string}> = ({
  tags,
  title,
}) => {
  const {linkWrapper} = textLink({interaction: 'underline'})
  const [isExpanded, setIsExpanded] = useState(false)
  const [hasOverflow, setHasOverflow] = useState(false)
  const tagGroupId = useId()

  const toggleExpanded = () => {
    setIsExpanded(prev => !prev)
  }

  const handleOverflowChange = useCallback((overflowState: boolean) => {
    setHasOverflow(overflowState)
  }, [])

  return (
    <div className="flex flex-col items-start gap-s2">
      <p className="arcadia-heading-9">{title}</p>
      <div id={tagGroupId}>
        <HgTagGroup
          tags={tags}
          maxTagsShown={isExpanded ? undefined : Infinity}
          canWrap={isExpanded}
          onOverflowChange={handleOverflowChange}
        />
      </div>
      {hasOverflow && (
        <Button
          onClick={toggleExpanded}
          aria-expanded={isExpanded}
          aria-controls={tagGroupId}
          className={linkWrapper({class: 'arcadia-ui-1'})}
        >
          {isExpanded ? 'See Less' : 'See All'}
        </Button>
      )}
    </div>
  )
}

export default HgDetailTagGroup
