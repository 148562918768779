import {folder, useControls} from 'leva'
import {useEffect} from 'react'
import {colors} from '~/design-system/hg/tokens'
import {usePrefersReducedMotion} from '~/hooks/useMediaQuery'

// THREE requires rotation to be in radians, but I think degrees is more intuitive
const INITIAL_ROTATION_DEGREES = {x: 10, y: -5, z: 33}

// rotate sphere to make the text more legible since the sphere will be static when reduced motion is enabled
const REDUCED_MOTION_ROTATION_DEGREES = {x: 0, y: 180, z: 20}

const SPHERE_VELOCITY_DECAY = 0.03 // Friction to slow down sphere rotation when dragging to add dampening
const POINTS_FRICTION_STRENGTH = 0.08 // Friction to slow down points as they spread out when the cursor interacts with them
const RETURN_SPEED = 0.02 // Speed at which points return to original positions after cursor interaction
const POINTER_FORCE = 15 // How much force to apply to points when the cursor interacts with them

export default function useSphereControls() {
  const prefersReducedMotion = usePrefersReducedMotion()
  const [controls, set] = useControls(() => ({
    'General Options': folder({
      pointerForce: {
        label: 'Pointer Force',
        value: POINTER_FORCE,
        min: 0,
        max: 50,
      },
      prefersReducedMotion: {
        label: 'Mock prefers-reduced-motion',
        value: prefersReducedMotion,
      },
      pointerArea: {
        label: 'Pointer Area',
        value: 1.2,
        min: 0.1,
        max: 4,
      },
    }),
    'Sphere Options': folder({
      sphereRotation: {
        label: 'Rotation',
        value: prefersReducedMotion
          ? REDUCED_MOTION_ROTATION_DEGREES
          : INITIAL_ROTATION_DEGREES,
        min: [-360, -360, -360],
        max: [360, 360, 360],
      },
      sphereVelocityDecay: {
        label: 'Drag Velocity Decay',
        value: SPHERE_VELOCITY_DECAY,
        min: 0,
        max: 0.1,
      },
    }),
    'Points Options': folder({
      pointsFrictionStrength: {
        label: 'Friction Strength',
        value: POINTS_FRICTION_STRENGTH,
        min: 0,
        max: 1,
      },
      returnSpeed: {
        label: 'Return Speed',
        value: RETURN_SPEED,
        min: 0,
        max: 0.1,
      },
      pointColor: {
        label: 'Color',
        value: colors.beige.base[300],
        options: Object.entries(colors.beige.base).reduce<Record<string, string>>(
          (acc, [key, value]) => ({
            ...acc,
            [`beige-${key}`]: value,
          }),
          {}
        ),
      },
      pointOpacity: {
        label: 'Opacity',
        value: 0.6,
        min: 0.2,
        max: 1,
      },
    }),
  }))
  useEffect(() => {
    set({
      sphereRotation: controls.prefersReducedMotion
        ? REDUCED_MOTION_ROTATION_DEGREES
        : INITIAL_ROTATION_DEGREES,
    })
  }, [controls.prefersReducedMotion, set])
  return controls
}
