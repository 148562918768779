import {bugsnagClient} from '~/utils/Bugsnag/client'
import * as Sentry from '@sentry/nextjs'

export type NotifyBugsnagOpts<CaughtErrorType = unknown> = {
  caughtError?: CaughtErrorType
  // TODO: this team is not used, we should implement it
  team?: 'creative-products'
  metadata?: Record<string, unknown>
  groupingHash?: string
}

const BUGSNAG_CAUGHT_ERROR_TAG = 'Caught Error'
const BUGSNAG_CUSTOM_METADATA_TAG = 'Custom Metadata'

/**
 * NOTE: mercury-web's implementation of this function is much more complex
 * but we don't need most of the features
 */
export function notifyBugsnag<CaughtErrorType = unknown>(
  errorMessage: string,
  opts?: NotifyBugsnagOpts<CaughtErrorType>
): void {
  notifySentry(errorMessage, opts)
  if (!bugsnagClient) return

  const {caughtError, metadata, groupingHash} = opts || {}

  bugsnagClient.notify(errorMessage, event => {
    if (caughtError) {
      event.addMetadata(BUGSNAG_CAUGHT_ERROR_TAG, {
        error: caughtError,
        errorClass: (caughtError as unknown as object)?.constructor?.name,
      })
    }

    if (metadata) {
      event.addMetadata(BUGSNAG_CUSTOM_METADATA_TAG, metadata)
    }

    if (groupingHash) {
      event.groupingHash = groupingHash
    }
  })
}

/**
 * EXPERIMENT: We are experimenting with Sentry
 */
export function notifySentry<CaughtErrorType = unknown>(
  errorMessage: string,
  opts?: NotifyBugsnagOpts<CaughtErrorType>
): void {
  const {caughtError, metadata, groupingHash} = opts || {}

  Sentry.withScope(scope => {
    if (caughtError) {
      scope.setTag(
        'error_type',
        (caughtError as unknown as object)?.constructor?.name
      )
      scope.setContext('caught_error', {
        error: caughtError,
      })
    }

    if (metadata) {
      scope.setContext('custom_metadata', metadata)
    }

    if (groupingHash) {
      scope.setFingerprint(['{{ default }}', groupingHash])
    }

    if (caughtError) {
      Sentry.captureException(caughtError)
    } else {
      Sentry.captureMessage(errorMessage)
    }
  })
}
