import {forwardRef} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {LinkWrapper, type LinkWrapperProps} from '~/design-system/foundations'
import HgIcon, {type HgIconType} from '../HgIcon'
import {type OmitStrict} from 'type-zoo'
import {omit} from 'lodash'

export type HgTextLinkInteraction = NonNullable<
  VariantProps<typeof textLink>['interaction']
>
export type LinkIconPosition = 'left' | 'right'
export type HgTextLinkOptions =
  | {
      iconProps?: {
        iconType: HgIconType
        position: LinkIconPosition
      }
    }
  | {newTab?: boolean}

export type HgTextLinkProps = OmitStrict<LinkWrapperProps, 'newTab'> &
  HgTextLinkOptions & {
    children: React.ReactNode | string
    variant: HgTextLinkInteraction
    analyticsEventName?: string
    className?: string
    hasIcon?: VariantProps<typeof textLink>['hasIcon']
    // These "force" states are solely for use in storybook to be able to see the different link states
    forceHover?: boolean
    forceFocusVisible?: boolean
    forceActive?: boolean
  }

export const textLink = tv({
  slots: {
    linkWrapper:
      'group w-full max-w-fit flex-row items-center rounded text-inherit text-text-default decoration-border-subdued underline-offset-4 outline-2 outline-offset-4 outline-border-focus hover:text-text-subdued hover:decoration-border-default hover:transition-colors hover:duration-300 hover:ease-out focus-visible:text-text-subdued focus-visible:decoration-border-default focus-visible:outline active:text-text-emphasized active:decoration-border-emphasized',
    linkChildren: 'decoration-1',
    icon: 'ms-s1',
  },
  variants: {
    interaction: {
      underline: {
        linkWrapper: 'underline',
      },
      revealUnderline: {
        linkWrapper:
          'no-underline hover:underline focus-visible:underline active:underline',
      },
      revealIcon: {
        linkWrapper: 'inline-flex',
        icon: 'opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100 group-focus-visible:opacity-100 group-active:opacity-100',
      },
      revealBackground: {
        linkWrapper:
          'rounded-full px-16 py-8 hover:bg-surface-emphasized hover:text-text-default focus-visible:bg-surface-emphasized focus-visible:text-text-default active:bg-surface-emphasized',
      },
    },
    hasIcon: {
      true: {
        linkWrapper: 'inline-flex',
      },
      false: {
        linkWrapper: 'inline',
      },
    },
    iconPosition: {
      left: {linkWrapper: 'flex-row-reverse', icon: 'me-s1'},
      right: {linkWrapper: 'flex-row', icon: 'ms-s1'},
    },
    forceHover: {
      true: {linkWrapper: 'text-text-subdued decoration-border-default'},
    },
    forceFocusVisible: {
      true: {linkWrapper: 'text-text-subdued decoration-border-default outline'},
    },
    forceActive: {
      true: {linkWrapper: 'text-text-emphasized decoration-border-emphasized'},
    },
  },
  compoundVariants: [
    {
      interaction: 'revealUnderline',
      forceHover: true,
      class: {linkWrapper: 'underline'},
    },
    {
      interaction: 'revealUnderline',
      forceFocusVisible: true,
      class: {linkWrapper: 'underline'},
    },
    {
      interaction: 'revealUnderline',
      forceActive: true,
      class: {linkWrapper: 'underline'},
    },
    {
      interaction: 'revealIcon',
      forceHover: true,
      class: {icon: 'opacity-100'},
    },
    {
      interaction: 'revealIcon',
      forceFocusVisible: true,
      class: {icon: 'opacity-100'},
    },
    {
      interaction: 'revealIcon',
      forceActive: true,
      class: {icon: 'opacity-100'},
    },
    {
      interaction: 'revealBackground',
      forceHover: true,
      class: {linkWrapper: 'bg-surface-emphasized text-text-default'},
    },
    {
      interaction: 'revealBackground',
      forceFocusVisible: true,
      class: {linkWrapper: 'bg-surface-emphasized text-text-default'},
    },
    {
      interaction: 'revealBackground',
      forceActive: true,
      class: {linkWrapper: 'bg-surface-emphasized'},
    },
  ],
})

const HgTextLink = forwardRef<HTMLAnchorElement, HgTextLinkProps>(
  (
    {
      children,
      variant,
      analyticsEventName,
      className,
      href,
      hasIcon,
      forceHover = false,
      forceFocusVisible = false,
      forceActive = false,
      ...props
    },
    ref
  ) => {
    const openInNewTab: boolean = 'newTab' in props ? !!props.newTab : false
    const iconProps = 'iconProps' in props ? props.iconProps : undefined
    const linkProps = omit(props, ['newTab', 'iconProps'])

    const {linkWrapper, linkChildren, icon} = textLink({
      interaction: variant,
      hasIcon: openInNewTab || !!iconProps || hasIcon,
      iconPosition: iconProps?.position,
      forceHover,
      forceFocusVisible,
      forceActive,
    })

    return (
      <LinkWrapper
        href={href}
        data-analytics-event-name={analyticsEventName}
        newTab={openInNewTab}
        ref={ref}
        className={linkWrapper({class: className})}
        {...linkProps}
      >
        <span className={linkChildren()}>{children}</span>
        {/* new tab option overrides any passed in icon */}
        {openInNewTab ? (
          <>
            <span className="sr-only">Opens in new tab</span>
            <HgIcon
              className={icon()}
              iconType="arrow-up-right-angle"
              size="inherit"
            />
          </>
        ) : (
          iconProps?.iconType && (
            <HgIcon
              className={icon()}
              iconType={iconProps.iconType}
              size="inherit"
            />
          )
        )}
      </LinkWrapper>
    )
  }
)
HgTextLink.displayName = 'HgTextLink'

export default HgTextLink
