'use client'

import NextVideo from 'next-video'
import Image from 'next/image'
import React, {useRef} from 'react'
import {
  HgAnimation,
  type HgAnimationProps,
  HgImage,
} from '~/design-system/hg/components'
import {type AspectRatio} from '~/design-system/hg/components/HgAspectRatio'
import {type Theme} from '~/design-system/hg/tokens/colors'
import {useIsVisible} from '~/design-system/hooks/useIsVisible'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'
import {type DatoVideoFragment} from './_generated/fragments.graphql'
import {cn} from '~/design-system/utils'

const DatoVideo = React.forwardRef<
  HTMLVideoElement,
  React.ComponentPropsWithoutRef<typeof NextVideo>
>((props, ref) => {
  return <NextVideo {...props} ref={ref} min-resolution="480p" />
})

DatoVideo.displayName = 'DatoVideo'

export type DatoAnimationProps = {
  data: DatoVideoFragment
  aspectRatio: AspectRatio
  buttonContainerId?: string
  loop?: boolean
  preRenderVideo?: boolean
  controlsTheme?: Theme
} & Pick<
  HgAnimationProps,
  'buttonVariant' | 'className' | 'autoplayVisibilityThreshold'
>

const DatoAnimation = ({
  data,
  aspectRatio,
  buttonContainerId,
  buttonVariant,
  loop,
  className,
  autoplayVisibilityThreshold,
  preRenderVideo = false,
  controlsTheme,
}: DatoAnimationProps) => {
  const assetRef = useRef<HTMLDivElement>(null)
  const showVideo = useIsVisible(assetRef, {
    rootMargin: '100%',
    once: true,
  })

  if (!data?.video?.video) {
    notifyBugsnag('Dato video asset missing video data', {
      metadata: {data},
    })
    return null
  }

  const {muxPlaybackId: playbackId, thumbnailUrl} = data.video.video
  // use either dato-provided poster or generate one from video thumbnail
  const {
    width: posterWidth,
    height: posterHeight,
    url: posterUrl,
    blurUpThumb,
  } = data.poster?.url
    ? data.poster
    : {...data.video.video, url: `${thumbnailUrl}?time=0`}

  const handlePlayError = (error: unknown) => {
    if (error instanceof DOMException) {
      // Ignore common errors from browsers not allowing videos to play
      if (
        ['NotAllowedError', 'AbortError', 'NotSupportedError'].includes(error.name)
      ) {
        return
      }
    }

    notifyBugsnag('Unable to play Dato video', {
      caughtError: error,
    })
  }

  return (
    <div
      ref={assetRef}
      className={cn('grid min-h-0', aspectRatio === 'none' && 'h-full')}
    >
      {/* We can't ensure that the thumbnail & video will have the same aspect ratio if aspect ratio is missing, so don't show it */}
      {blurUpThumb && aspectRatio !== 'none' && (
        <HgImage className="col-start-1 col-end-auto row-start-1 row-end-auto h-full min-h-0">
          <Image
            src={blurUpThumb}
            alt=""
            width={posterWidth}
            height={posterHeight}
          />
        </HgImage>
      )}
      {/**
       * NB: To avoid excessive video streaming charges, we usually wait to render video player until it is one viewport away.
       * `preRenderVideo` allows us to escape this behavior- it will allow the video player to be immediately rendered, but not autoplayed.
       */}
      {(preRenderVideo || showVideo) && (
        <div className="col-start-1 col-end-auto row-start-1 row-end-auto h-full min-h-0">
          <div className={cn('grid h-fit', aspectRatio === 'none' && 'h-full')}>
            <HgAnimation
              aspectRatio={aspectRatio}
              controlsTheme={controlsTheme}
              showVolumeButton={!!data.showVolumeButton}
              buttonContainerId={buttonContainerId}
              buttonVariant={buttonVariant}
              asset={
                <DatoVideo playbackId={playbackId}>
                  <Image
                    slot="poster"
                    src={posterUrl}
                    placeholder="blur"
                    blurDataURL={blurUpThumb || undefined}
                    alt=""
                    width={posterWidth}
                    height={posterHeight}
                  />
                </DatoVideo>
              }
              onPlayError={handlePlayError}
              preload="auto"
              loop={loop}
              className={className}
              autoplayVisibilityThreshold={autoplayVisibilityThreshold}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DatoAnimation
