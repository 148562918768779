/* eslint-disable react/no-unknown-property */
'use client'
import {Canvas, type CanvasProps} from '@react-three/fiber'
import {Suspense, useEffect, useRef, useState} from 'react'
import {PerspectiveCamera} from '@react-three/drei'
import Sphere from './Sphere'
import {grid} from '~/design-system/foundations'
import IntersectionUpdater, {normalizePointerPosition} from './IntersectionUpdater'
import {useSphereContext} from './SphereProvider'
import {cn} from '~/design-system/utils'
import {HgButtonLink, HgIcon} from '~/design-system/hg/components'
import {useSearchParams} from 'next/navigation'
import {Leva} from 'leva'
import {useIsMobile} from '~/hooks/useMediaQuery'
import {internalLinks} from '~/routing/internal-links'

export default function SphereCanvas() {
  const {
    containerRef,
    dragRotationVelocityRef,
    sphereRef,
    lastMousePosition,
    pointerDelta,
    pointerVelocity,
  } = useSphereContext()

  const params = useSearchParams()
  const showDebugPanel = params.has('debug')

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [frameloop, setFrameloop] = useState<CanvasProps['frameloop']>('never')
  const [isDragging, setIsDragging] = useState(false)
  const isMobile = useIsMobile()

  // Stop canvas animation when it's not in view
  useEffect(() => {
    if (!canvasRef.current) return
    const observer = new IntersectionObserver(([{isIntersecting}]) => {
      setFrameloop(isIntersecting ? 'always' : 'never')
    }, {})

    observer.observe(canvasRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <>
      <div className="absolute right-0 top-navbar-height z-10 w-[400]">
        <Leva hidden={!showDebugPanel} fill />
      </div>
      <div
        className={grid({
          className: cn(
            'm-auto h-[calc(100svh+var(--s8))]',
            isDragging && 'motion-safe:cursor-grabbing motion-safe:touch-none'
          ),
        })}
        ref={containerRef}
        onPointerUp={e => {
          setIsDragging(false)
          const {x, y} = normalizePointerPosition(
            e.clientX,
            e.clientY,
            containerRef.current
          )
          lastMousePosition.current = {x, y}
          pointerVelocity.current = 0
        }}
        onPointerOut={() => {
          pointerVelocity.current = 0
        }}
        onPointerDown={e => {
          if (isMobile || !containerRef.current) return
          setIsDragging(true)
          // prevent highlighting text
          e.preventDefault()
          const target = e.target as HTMLDivElement
          target.setPointerCapture(e.pointerId)
          const {x, y} = normalizePointerPosition(
            e.clientX,
            e.clientY,
            containerRef.current
          )
          lastMousePosition.current = {x, y}
        }}
        onPointerMove={() => {
          if (!isDragging || !sphereRef.current || !containerRef.current) return

          const {deltaX, deltaY} = pointerDelta.current

          dragRotationVelocityRef.current.y += deltaX * 0.03
          dragRotationVelocityRef.current.x += deltaY * 0.03

          sphereRef.current.userData.dragRotationVelocity =
            dragRotationVelocityRef.current
        }}
      >
        <div className="relative z-[1] col-span-full col-start-1 row-start-1 grid h-svh grid-cols-subgrid pb-s6 pt-navbar-height">
          <div className="col-span-full grid grid-cols-subgrid justify-between">
            <div className="col-span-full pt-s6">
              <h1 className="text-text-default arcadia-display-1">Spheres</h1>
              <h2 className="text-beige-magic-600 arcadia-display-5">
                May 20, 2025
              </h2>
              <HgButtonLink
                href={internalLinks.spheresUpdatesForm}
                variant="frosted"
                className="mt-s4"
                data-analytics-event-name="spheres-updates-form"
              >
                Get Updates
              </HgButtonLink>
            </div>
            <div className="col-span-full flex justify-end sm:col-span-8 sm:col-start-5 lg:col-span-6 lg:col-start-11">
              <div className="flex  flex-col justify-end gap-s6 drop-shadow-[2px_2px_2px_var(--background-default)]">
                <div className="flex flex-col gap-s2 text-text-default">
                  <span className="arcadia-heading-5">
                    The conference for ambitious founders
                  </span>
                  <p className="arcadia-body-2">
                    Save the date for Mercury Spheres, where founders gather to
                    reimagine the possible and let big ideas take shape. Get
                    actionable solutions to your current challenges, expand your
                    network (and your thinking), and come away with strategies and
                    inspiration to propel your company forward.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-s4">
                  <div className="flex flex-col">
                    <span className="text-text-default arcadia-heading-9">
                      <HgIcon className="mr-4" iconType="map-pin" size="large" />{' '}
                      Location
                    </span>
                    <span className="text-text-subdued arcadia-body-2">
                      San Francisco, CA
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-text-default arcadia-heading-9">
                      <HgIcon className="mr-4" iconType="ticket" size="large" />{' '}
                      Tickets
                    </span>
                    <span className="text-text-subdued arcadia-body-2">
                      On sale March 2025
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-0 col-span-full col-start-1 row-start-1 -mx-s5 h-[calc(100svh+var(--s8))]">
          <Canvas ref={canvasRef} frameloop={frameloop}>
            <Suspense fallback={null}>
              <Sphere />
              <PerspectiveCamera makeDefault position={[0, 0, 26]} />
              <IntersectionUpdater />
            </Suspense>
          </Canvas>
        </div>
      </div>
    </>
  )
}
