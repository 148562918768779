import {type LinkComponentFragment} from '~/cms/components/LinkComponent/_generated/fragments.graphql'
import {
  type LabelButtonIconPosition,
  type HgIconType,
} from '~/design-system/hg/components'

export function getLinkProps(data: LinkComponentFragment | undefined | null) {
  if (!data) return undefined
  return {
    'data-analytics-event-name': data.link.analyticsEvent?.name,
    'children': data.label,
    'href': data.link.href,
    'iconProps': data.iconType
      ? {
          iconType: data.iconType.iconType as HgIconType,
          position: (data.iconPosition as LabelButtonIconPosition) ?? 'right',
        }
      : undefined,
    'target': data.openInNewTab ? '_blank' : undefined,
    'newTab': data.openInNewTab,
  }
}
