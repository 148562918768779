import {type ReactNode} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {HgIcon} from '~/design-system/hg/components'

const listItemsWrapper = tv({
  base: 'list-none space-y-s2 text-left',
  variants: {
    topPadding: {
      s0: 'pt-s0',
      s4: 'pt-s4',
    },
  },
})

type HgBulletedListVariants = VariantProps<typeof listItemsWrapper>

export type HgBulletedListProps = {
  listItems:
    | readonly [string, string?, string?, string?, string?]
    | readonly [ReactNode, ReactNode?, ReactNode?, ReactNode?, ReactNode?]
} & HgBulletedListVariants

const HgBulletedList = ({listItems, topPadding = 's0'}: HgBulletedListProps) => {
  return (
    <ul className={listItemsWrapper({topPadding})}>
      {listItems.slice(0, 5).map((item, index) => (
        <li className="flex items-start" key={index}>
          <span className="me-s2 text-icon-default">
            <HgIcon iconType="arrow-right" size="small" className="md:hidden" />
            <HgIcon
              iconType="arrow-right"
              size="medium"
              className="hidden md:inline-block lg:hidden"
            />
            <HgIcon
              iconType="arrow-right"
              size="large"
              className="hidden lg:inline-block"
            />
          </span>
          <span className="text-text-default arcadia-body-2">{item}</span>
        </li>
      ))}
    </ul>
  )
}

export default HgBulletedList
