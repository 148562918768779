'use client'

import {forwardRef, useState} from 'react'
import {Button} from '~/design-system/foundations'
import {HgTooltip} from '~/design-system/hg/components'
import HgButtonContent, {PrimaryButtonWrapper} from './HgButtonContent'
import {type HgButtonProps} from '../types'
import {hgButtonVariants} from '../variants'
import {renderWithTooltip} from '../utils'

const HgButton = forwardRef<HTMLButtonElement, HgButtonProps>((props, ref) => {
  const [isActive, setIsActive] = useState(false)
  const {
    className,
    variant = 'primary',
    size = 'medium',
    contentType = 'label',
    uiState,
    disabled,
    children,
    iconProps,
    onClick,
    onKeyDown,
    onKeyUp,
    ...buttonProps
  } = props

  const isDisabled = uiState === 'disabled' || disabled
  const {button} = hgButtonVariants({
    variant,
    size,
    uiState: isActive ? 'active' : uiState,
    contentType,
  })

  const renderButtonContent = () => {
    if (variant === 'primary') {
      return (
        <PrimaryButtonWrapper>
          <HgButtonContent
            variant="primary"
            uiState={uiState}
            iconProps={iconProps}
            contentType={contentType}
            size={size}
          >
            {children}
          </HgButtonContent>
        </PrimaryButtonWrapper>
      )
    }

    return (
      <HgButtonContent
        variant={variant}
        uiState={uiState}
        iconProps={iconProps}
        size={size}
        contentType={contentType}
      >
        {children}
      </HgButtonContent>
    )
  }

  const renderBaseButton = () => (
    <Button
      data-button-variant={variant}
      className={button({class: className})}
      aria-disabled={isDisabled}
      aria-busy={uiState === 'pending'}
      onClick={e => {
        if (isDisabled || uiState === 'pending') {
          e.preventDefault()
          return
        }
        onClick?.(e)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          setIsActive(true)
        }
        onKeyDown?.(e)
      }}
      onKeyUp={e => {
        setIsActive(false)
        onKeyUp?.(e)
      }}
      ref={ref}
      {...buttonProps}
    >
      {renderButtonContent()}
    </Button>
  )

  if (renderWithTooltip(props)) {
    return (
      <HgTooltip content={props.tooltip} size={size} side="bottom">
        {renderBaseButton()}
      </HgTooltip>
    )
  }

  return renderBaseButton()
})

HgButton.displayName = 'HgButton'

export default HgButton
