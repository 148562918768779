export const arcadiaStyles = {
  '.arcadia-display-1': {
    '@apply font-display font-480 tracking-normal leading-110% ~text-5xl/11xl': {},
  },
  '.arcadia-display-2': {
    '@apply font-display font-480 tracking-normal leading-110% ~text-4xl/10xl': {},
  },
  '.arcadia-display-3': {
    '@apply font-display font-480 tracking-normal leading-110% ~text-3xl/9xl': {},
  },
  '.arcadia-display-4': {
    '@apply font-display font-480 tracking-normal leading-110% ~text-2xl/8xl': {},
  },
  '.arcadia-display-5': {
    '@apply font-display font-360 tracking-normal leading-110% ~text-5xl/11xl': {},
  },
  '.arcadia-display-6': {
    '@apply font-display font-360 tracking-normal leading-110% ~text-4xl/10xl': {},
  },
  '.arcadia-display-7': {
    '@apply font-display font-360 tracking-normal leading-110% ~text-3xl/9xl': {},
  },
  '.arcadia-display-8': {
    '@apply font-display font-360 tracking-normal leading-110% ~text-2xl/8xl': {},
  },
  '.arcadia-heading-1': {
    '@apply font-display font-480 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl tracking-1.5% lg:tracking-1% 2xl:tracking-0.5% leading-120% md:leading-115% 3xl:leading-110%':
      {},
  },
  '.arcadia-heading-2': {
    '@apply font-display font-480 text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl 3xl:text-6xl tracking-1.5% lg:tracking-1% 3xl:tracking-0.5% leading-120% md:leading-115%':
      {},
  },
  '.arcadia-heading-3': {
    '@apply font-display font-480 text-xl md:text-2xl lg:text-3xl 2xl:text-4xl 3xl:text-5xl tracking-2% md:tracking-1.5% 2xl:tracking-1% leading-120% lg:leading-115% 2xl:tracking-1%':
      {},
  },
  '.arcadia-heading-4': {
    '@apply font-display font-480 text-xl lg:text-2xl 2xl:text-3xl 3xl:text-4xl tracking-2% lg:tracking-1.5% 3xl:tracking-1% leading-120% 2xl:leading-115%':
      {},
  },
  '.arcadia-heading-5': {
    '@apply font-display font-480 text-lg md:text-xl lg:text-2xl 2xl:text-3xl tracking-2% lg:tracking-1.5% leading-120% 2xl:leading-115%':
      {},
  },
  '.arcadia-heading-6': {
    '@apply font-display font-480 text-lg lg:text-xl 2xl:text-2xl tracking-2% 2xl:tracking-1.5% leading-120%':
      {},
  },
  '.arcadia-heading-7': {
    '@apply font-display font-480 text-md lg:text-lg 2xl:text-xl tracking-3% lg:tracking-2% leading-135% lg:leading-120%':
      {},
  },
  '.arcadia-heading-8': {
    '@apply font-sans font-480 text-base md:text-md lg:text-lg tracking-normal leading-135% lg:leading-120%':
      {},
  },
  '.arcadia-heading-9': {
    '@apply font-sans font-480 text-sm md:text-base lg:text-md tracking-normal leading-135%':
      {},
  },
  '.arcadia-heading-10': {
    '@apply font-sans font-480 text-sm lg:text-base tracking-1% lg:tracking-normal leading-140% lg:leading-135%':
      {},
  },
  '.arcadia-heading-11': {
    '@apply font-sans font-480 text-xs lg:text-sm tracking-1.5% lg:tracking-1% leading-140%':
      {},
  },
  '.arcadia-heading-12': {
    '@apply font-sans font-480 text-xxs lg:text-xs tracking-2% lg:tracking-1.5% leading-140%':
      {},
  },
  '.arcadia-subheading-1': {
    '@apply font-display font-360 leading-120% md:leading-115% 3xl:leading-110% text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl tracking-1.5% lg:tracking-1% 2xl:tracking-0.5%':
      {},
  },
  '.arcadia-subheading-2': {
    '@apply font-display font-360 leading-120% md:leading-115% text-2xl md:text-3xl lg:text-4xl 2xl:text-6xl 3xl:text-7xl tracking-1.5% lg:tracking-1% 3xl:tracking-0.5%':
      {},
  },
  '.arcadia-subheading-3': {
    '@apply font-display font-360 leading-120% lg:leading-115% text-xl md:text-2xl lg:text-3xl 2xl:text-4xl 3xl:text-5xl tracking-2% md:tracking-1.5% 2xl:tracking-1%':
      {},
  },
  '.arcadia-subheading-4': {
    '@apply font-display font-360 leading-120% 2xl:leading-115% text-xl lg:text-2xl 2xl:text-3xl 3xl:text-4xl tracking-2% lg:tracking-1.5% 3xl:tracking-1%':
      {},
  },
  '.arcadia-subheading-5': {
    '@apply font-display font-360 leading-120% 2xl:leading-115% text-lg md:text-xl lg:text-2xl 2xl:text-3xl tracking-2% lg:tracking-1.5%':
      {},
  },
  '.arcadia-subheading-6': {
    '@apply font-display font-360 leading-120% text-lg lg:text-xl 2xl:text-2xl tracking-2% 2xl:tracking-1.5%':
      {},
  },
  '.arcadia-subheading-7': {
    '@apply font-display font-360 leading-135% lg:leading-120% text-md lg:text-lg 2xl:text-xl tracking-3% lg:tracking-2%':
      {},
  },
  '.arcadia-body-1': {
    '@apply font-sans font-360 tracking-normal leading-135% text-base md:text-md lg:text-lg':
      {},
  },
  '.arcadia-body-2': {
    '@apply font-sans font-360 tracking-normal leading-135% text-sm md:text-base lg:text-md tracking-normal':
      {},
  },
  '.arcadia-body-3': {
    '@apply font-sans font-400 leading-140% text-sm lg:text-base tracking-1% lg:tracking-normal':
      {},
  },
  '.arcadia-body-4': {
    '@apply font-sans font-400 leading-140% text-xs lg:text-sm tracking-2% lg:tracking-1%':
      {},
  },
  '.arcadia-body-5': {
    '@apply font-sans font-400 leading-140% text-xxs lg:text-xs tracking-3% lg:tracking-2%':
      {},
  },
  '.arcadia-ui-1': {
    '@apply font-sans font-420 leading-none text-base tracking-normal': {},
  },
  '.arcadia-ui-2': {
    '@apply font-sans font-420 text-sm leading-none tracking-0.5%': {},
  },
  '.arcadia-ui-3': {
    '@apply font-sans font-480 text-xs leading-none tracking-1%': {},
  },
  '.arcadia-ui-4': {
    '@apply font-sans font-500 text-sm leading-none tracking-2% uppercase': {},
  },
}

export const tiemposStyles = {
  '.tiempos-display-1': {
    '@apply font-tiempos-fine font-500 tracking-normal leading-105% ~text-5xl/11xl':
      {},
  },
  '.tiempos-display-2': {
    '@apply font-tiempos-fine font-500 tracking-normal leading-105% ~text-4xl/10xl':
      {},
  },
  '.tiempos-display-3': {
    '@apply font-tiempos-fine font-500 tracking-normal leading-105% ~text-3xl/9xl':
      {},
  },
  '.tiempos-display-4': {
    '@apply font-tiempos-fine font-500 tracking-normal leading-105% ~text-2xl/8xl':
      {},
  },
  '.tiempos-display-5': {
    '@apply font-tiempos-fine italic font-300 tracking-normal leading-105% ~text-5xl/11xl':
      {},
  },
  '.tiempos-display-6': {
    '@apply font-tiempos-fine italic font-300 tracking-normal leading-105% ~text-4xl/10xl':
      {},
  },
  '.tiempos-display-7': {
    '@apply font-tiempos-fine italic font-300 tracking-normal leading-105% ~text-3xl/9xl':
      {},
  },
  '.tiempos-display-8': {
    '@apply font-tiempos-fine italic font-300 tracking-normal leading-105% ~text-2xl/8xl':
      {},
  },
  '.tiempos-heading-1': {
    '@apply font-tiempos-fine font-500 text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl tracking-normal leading-110% 3xl:leading-105%':
      {},
  },
  '.tiempos-heading-2': {
    '@apply font-tiempos-fine font-500 text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl 3xl:text-6xl tracking-1.5% md:tracking-1% 2xl:tracking-0.5% 3xl:tracking-normal leading-120% lg:leading-110%':
      {},
  },
  '.tiempos-heading-3': {
    '@apply font-tiempos-fine font-500 text-xl md:text-2xl lg:text-3xl 2xl:text-4xl 3xl:text-5xl tracking-2% md:tracking-1.5% lg:tracking-1% 3xl:tracking-0.5% leading-125% lg:leading-120% 2xl:leading-110%':
      {},
  },
  '.tiempos-heading-4': {
    '@apply font-tiempos-fine font-500 text-xl lg:text-2xl 2xl:text-3xl 3xl:text-4xl tracking-2% lg:tracking-1.5% 2xl:tracking-1% leading-125% lg:leading-120% 3xl:leading-110%':
      {},
  },
  '.tiempos-heading-5': {
    '@apply font-tiempos-headline font-400 text-lg md:text-xl lg:text-2xl 2xl:text-3xl tracking-normal leading-125% lg:leading-120%':
      {},
  },
  '.tiempos-heading-6': {
    '@apply font-tiempos-headline font-400 text-lg lg:text-xl 2xl:text-2xl tracking-normal leading-125% 2xl:leading-120%':
      {},
  },
  '.tiempos-heading-7': {
    '@apply font-tiempos-headline font-400 text-md lg:text-lg 2xl:text-xl tracking-normal leading-125%':
      {},
  },
  '.tiempos-heading-8': {
    '@apply font-tiempos font-500 text-md lg:text-lg tracking-normal leading-125%':
      {},
  },
  '.tiempos-heading-9': {
    '@apply font-tiempos font-500 text-base lg:text-md tracking-normal leading-125%':
      {},
  },
  '.tiempos-heading-10': {
    '@apply font-tiempos font-500 text-sm lg:text-base tracking-normal leading-125%':
      {},
  },
  '.tiempos-heading-11': {
    '@apply font-tiempos font-500 text-xs lg:text-sm tracking-1% lg:tracking-normal leading-125%':
      {},
  },
  '.tiempos-heading-12': {
    '@apply font-tiempos font-500 text-xxs lg:text-xs tracking-1.5% lg:tracking-1% leading-125%':
      {},
  },
  '.tiempos-subheading-1': {
    '@apply font-tiempos-fine italic font-400 leading-120% lg:leading-110% 3xl:leading-105% text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl tracking-2% md:tracking-1% xl:tracking-0.5% 2xl:tracking-normal 3xl:tracking-1%':
      {},
  },
  '.tiempos-subheading-2': {
    '@apply font-tiempos-fine italic font-400 leading-125% lg:leading-110% text-2xl md:text-3xl lg:text-4xl 2xl:text-6xl 3xl:text-7xl tracking-1.5% md:tracking-1% 2xl:tracking-0.5% 3xl:tracking-normal':
      {},
  },
  '.tiempos-subheading-3': {
    '@apply font-tiempos-fine italic font-400 leading-125% md:leading-120% 2xl:leading-110% text-xl md:text-2xl lg:text-3xl 2xl:text-4xl 3xl:text-5xl tracking-2% md:tracking-1.5% lg:tracking-1% 3xl:tracking-0.5%':
      {},
  },
  '.tiempos-subheading-4': {
    '@apply font-tiempos-fine italic font-400 leading-125% lg:leading-120% 3xl:leading-110% text-xl lg:text-2xl 2xl:text-3xl 3xl:text-4xl tracking-2% lg:tracking-1.5% 2xl:tracking-1%':
      {},
  },
  '.tiempos-subheading-5': {
    '@apply font-tiempos-headline italic font-400 leading-125% lg:leading-120% text-lg md:text-xl lg:text-2xl 2xl:text-3xl tracking-normal':
      {},
  },
  '.tiempos-subheading-6': {
    '@apply font-tiempos-headline italic font-400 leading-125% lg:leading-120% text-lg lg:text-xl 2xl:text-2xl tracking-normal':
      {},
  },
  '.tiempos-subheading-7': {
    '@apply font-tiempos-headline italic font-400 leading-125% text-md lg:text-lg 2xl:text-xl tracking-normal':
      {},
  },
  '.tiempos-subheading-8': {
    '@apply font-tiempos italic font-400 leading-125% text-md lg:text-lg tracking-normal':
      {},
  },
  '.tiempos-subheading-9': {
    '@apply font-tiempos italic font-400 leading-125% text-base lg:text-md tracking-normal':
      {},
  },
  '.tiempos-subheading-10': {
    '@apply font-tiempos italic font-400 leading-125% text-sm lg:text-base tracking-normal':
      {},
  },
  '.tiempos-subheading-11': {
    '@apply font-tiempos italic font-400 leading-125% text-xs lg:text-sm tracking-1.5% lg:tracking-normal':
      {},
  },
  '.tiempos-subheading-12': {
    '@apply font-tiempos italic font-400 leading-125% text-xxs lg:text-xxs tracking-1.5% lg:tracking-1%':
      {},
  },
  '.tiempos-body-1': {
    '@apply font-tiempos decoration-from-font tracking-normal leading-140% text-base md:text-md lg:text-lg':
      {},
  },
  '.tiempos-body-2': {
    '@apply font-tiempos decoration-from-font tracking-normal leading-140% text-sm md:text-base lg:text-md tracking-normal':
      {},
  },
  '.tiempos-body-3': {
    '@apply font-tiempos decoration-from-font leading-140% text-sm lg:text-base tracking-normal':
      {},
  },
  '.tiempos-body-4': {
    '@apply font-tiempos decoration-from-font leading-140% text-xs lg:text-sm tracking-1% lg:tracking-normal':
      {},
  },
  '.tiempos-body-5': {
    '@apply font-tiempos decoration-from-font leading-140% text-xxs lg:text-xs tracking-1.5% lg:tracking-1%':
      {},
  },
  '.tiempos-dropcap-1': {
    '@apply font-tiempos-fine font-300 leading-normal text-[77px] lg:text-[87px] tracking-normal':
      {},
  },
}

const fontVariants = {
  '.small-caps': {
    'font-variant': 'small-caps',
  },
}

export const typographyUtilities = {
  ...arcadiaStyles,
  ...tiemposStyles,
  ...fontVariants,
}
