'use client'

import {useState, useId, useCallback} from 'react'
import {tv} from 'tailwind-variants'
import {Button} from '~/design-system/foundations'
import {HgTagGroup, type HgTagProps} from '~/design-system/hg/components'
import {textLink} from '~/design-system/hg/components/HgTextLink'
import {cn} from '~/design-system/utils'

const TagGroupVariants = tv({
  slots: {
    tagGroup: 'order-3 col-span-full flex lg:col-span-12 lg:col-start-3 ',
    tagGroupContent: '',
    tagGroupOverflowButton: '',
  },
  variants: {
    hasFilters: {
      false: {
        tagGroupContent: 'hidden',
        tagGroupOverflowButton: 'hidden',
      },
    },
    hasOverflow: {
      false: {
        tagGroupOverflowButton: 'hidden',
      },
      true: {
        tagGroupOverflowButton: 'self-end',
      },
    },
  },
})

const ExpandableTagGroup = ({tags}: {tags: HgTagProps[]}) => {
  const {linkWrapper} = textLink({interaction: 'underline'})
  const [isTagGroupExpanded, setIsTagGroupExpanded] = useState(false)
  const [tagGroupHasOverflow, setTagGroupHasOverflow] = useState(false)
  const tagGroupId = useId()

  const {tagGroup, tagGroupContent, tagGroupOverflowButton} = TagGroupVariants({
    hasFilters: tags.length !== 0,
    hasOverflow: tagGroupHasOverflow,
  })

  const handleTagOverflowChange = useCallback((overflowState: boolean) => {
    setTagGroupHasOverflow(overflowState)
  }, [])

  const toggleTagGroup = () => {
    setIsTagGroupExpanded(prev => !prev)
  }

  return (
    <div id={tagGroupId} className={tagGroup()}>
      <div className={tagGroupContent()}>
        <HgTagGroup
          tags={tags}
          maxTagsShown={isTagGroupExpanded ? undefined : Infinity}
          canWrap={isTagGroupExpanded}
          onOverflowChange={handleTagOverflowChange}
        />
      </div>
      <Button
        onClick={toggleTagGroup}
        aria-expanded={isTagGroupExpanded}
        aria-controls={tagGroupId}
        className={cn(linkWrapper(), tagGroupOverflowButton())}
      >
        {isTagGroupExpanded ? 'See Less' : 'See All'}
      </Button>
    </div>
  )
}

export default ExpandableTagGroup
