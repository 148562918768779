'use client'

import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import HgChevronIndicator from './HgChevronIndicator'
import {HgNavigationFooter} from './HgNavigationFooter'
import {HgNavigationSubMenu} from './HgNavigationSubMenu'
import {type HgNavigationMenuProps} from './types'
import {textLink} from '../HgTextLink'

export const HgNavigationMenu = ({
  menuTitle,
  subMenus,
  footerProps,
}: HgNavigationMenuProps) => {
  const {linkWrapper} = textLink({interaction: 'revealBackground', hasIcon: true})
  // Necessary to prevent navigation menu being opened/ closed on hover
  // https://github.com/radix-ui/primitives/issues/1630
  const preventHover = (e: React.PointerEvent) => {
    e.preventDefault()
  }

  return (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger
        onPointerEnter={preventHover}
        onPointerMove={preventHover}
        onPointerLeave={preventHover}
        className={linkWrapper({
          className:
            'arcadia-ui-1 data-[state=open]:bg-surface-emphasized data-[state=open]:text-text-emphasized',
        })}
      >
        {menuTitle}
        <HgChevronIndicator className="ms-4" />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content
        onPointerEnter={preventHover}
        onPointerLeave={preventHover}
        className={
          'bg-transparent absolute left-0 top-0 w-auto rounded-2xl p-s5 motion-safe:data-[motion=from-end]:animate-enterMenu motion-safe:data-[motion=from-start]:animate-enterMenu motion-safe:data-[motion=to-end]:animate-exitMenu motion-safe:data-[motion=to-start]:animate-exitMenu'
        }
      >
        <div className="grid w-auto grid-cols-[1fr] gap-x-s5 gap-y-s7 [&>*:nth-child(even)]:col-start-2">
          {subMenus.map((subMenu, index) => {
            return (
              subMenu && (
                <HgNavigationSubMenu
                  key={index}
                  menuLabel={`${index}`}
                  {...subMenu}
                />
              )
            )
          })}
        </div>

        {footerProps?.footerLinks?.length && (
          <div className="pt-24">
            <HgNavigationFooter {...footerProps} />
          </div>
        )}
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  )
}
