import {forwardRef} from 'react'
import {Button, LinkWrapper} from '~/design-system/foundations'
import {HgTooltip} from '~/design-system/hg/components'
import HgButtonContent, {PrimaryButtonWrapper} from './HgButtonContent'
import {type HgButtonLinkProps} from '../types'
import {hgButtonVariants} from '../variants'
import {renderWithTooltip} from '../utils'

export const HgButtonLink = forwardRef<HTMLButtonElement, HgButtonLinkProps>(
  (props, ref) => {
    const {
      className,
      variant = 'primary',
      size = 'medium',
      uiState,
      contentType = 'label',
      iconProps,
      children,
      ...linkProps
    } = props

    const {button} = hgButtonVariants({
      variant,
      size,
      uiState,
      contentType,
    })

    const renderLinkContent = () => {
      if (variant === 'primary') {
        return (
          <PrimaryButtonWrapper>
            <HgButtonContent
              variant="primary"
              uiState={uiState}
              iconProps={iconProps}
              contentType={contentType}
              size={size}
            >
              {children}
            </HgButtonContent>
          </PrimaryButtonWrapper>
        )
      }

      return (
        <HgButtonContent
          variant={variant}
          uiState={uiState}
          iconProps={iconProps}
          size={size}
          contentType={contentType}
        >
          {children}
        </HgButtonContent>
      )
    }

    const renderBaseLink = () => (
      <Button
        asChild
        ref={ref}
        className={button({class: className})}
        data-button-variant={variant}
      >
        <LinkWrapper {...linkProps}>{renderLinkContent()}</LinkWrapper>
      </Button>
    )

    if (renderWithTooltip(props)) {
      return (
        <HgTooltip content={props.tooltip} size={size} side="bottom">
          {renderBaseLink()}
        </HgTooltip>
      )
    }

    return renderBaseLink()
  }
)

HgButtonLink.displayName = 'HgButtonLink'

export default HgButtonLink
