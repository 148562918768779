import type {HgButtonProps, HgButtonLinkProps} from './types'

export function renderWithTooltip(
  props: HgButtonProps | HgButtonLinkProps
): props is (HgButtonProps | HgButtonLinkProps) & {
  contentType: 'icon'
  tooltip: string
} {
  return props.contentType === 'icon' && 'tooltip' in props && !!props.tooltip
}
