'use client'

import {useEffect} from 'react'
import {notifyBugsnag} from './notify'
import type Error from 'next/error'

type UseBugsnagErrorBoundaryProps = {
  error: Error
}
/**
 * Typically we would wrap the entire application in an ErrorBoundary component, however
 * Next.js allows you to define custom child error pages which may absorb errors. As a result
 * each error file should use this hook to report errors to Bugsnag.
 *
 * Docs: https://nextjs.org/docs/app/building-your-application/routing/error-handling
 *
 * Note to future developer: Check out how Sentry ended up doing it, it might be more intuitive
 * https://github.com/getsentry/sentry-javascript/issues/7181
 */
export function useBugsnagErrorBoundary(props: UseBugsnagErrorBoundaryProps) {
  const {error} = props
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const message = `ErrorBoundary: ${'message' in error ? error.message : ''}`
    notifyBugsnag(message, {
      caughtError: error,
      metadata: {errorBoundary: true},
    })
  }, [error])
}
