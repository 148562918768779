import {useMemo} from 'react'
import {Pagination, PaginationContent} from '~/design-system/foundations/Pagination'
import {HgButtonLink, HgDropdown} from '~/design-system/hg/components'

export type HgPaginationProps = {
  totalPages: number
  onChange: (value: string) => void
  currentPage: number
  nextPageLink: string
  prevPageLink: string
}

export default function HgPagination({
  totalPages,
  onChange,
  currentPage,
  prevPageLink,
  nextPageLink,
}: HgPaginationProps) {
  const pageOptions = useMemo(
    () =>
      Array.from({length: totalPages}, (_, i) => ({
        value: `${i + 1}`,
        label: `${i + 1}`.padStart(2, '0'),
      })),
    [totalPages]
  )

  function focusFirstTableItem() {
    const firstTableItem = document.querySelector(
      'tbody > tr a'
    ) as HTMLAnchorElement
    firstTableItem?.focus()
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLAnchorElement>) {
    if (event.key === 'Enter') {
      focusFirstTableItem()
      event.currentTarget.click()
    }
  }

  return (
    <Pagination>
      <PaginationContent className="flex items-center gap-s2 arcadia-body-3">
        <HgButtonLink
          variant="text"
          contentType="icon"
          size="xs"
          href={prevPageLink}
          onClick={() => {
            onChange?.(`${currentPage - 1}`)
          }}
          iconProps={{iconType: 'chevron-left'}}
          aria-label="Previous page"
          aria-hidden={currentPage === 1}
          className="aria-[hidden=true]:hidden"
          onKeyDown={handleKeyDown}
        />
        Page
        <HgDropdown
          options={pageOptions}
          value={currentPage.toString()}
          onChange={value => {
            onChange?.(value)
            focusFirstTableItem()
          }}
          onOptionKeyDown={event => {
            if (event.key === 'Enter') {
              // Hacky way to ensure this runs after the dropdown onChange triggers
              setTimeout(() => {
                focusFirstTableItem()
              }, 0)
            }
          }}
          classNames={{trigger: '[&_span]:w-16'}}
          autoFocusOnClose={false}
        />
        of {totalPages.toString().padStart(2, '0')}
        <HgButtonLink
          variant="text"
          contentType="icon"
          size="xs"
          onClick={() => {
            onChange?.(`${currentPage + 1}`)
          }}
          href={nextPageLink}
          iconProps={{iconType: 'chevron-right'}}
          aria-label="Next page"
          aria-hidden={currentPage === totalPages}
          className="aria-[hidden=true]:hidden"
          onKeyDown={handleKeyDown}
        />
      </PaginationContent>
    </Pagination>
  )
}
