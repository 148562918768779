import React, {type ReactNode} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {TextTag, type TextTagProps} from '~/design-system/foundations'
import {HgButtonLink, type HgButtonLinkProps} from '~/design-system/hg/components'

const textWrapper = tv({
  base: 'flex flex-col',
  variants: {
    alignment: {
      left: 'items-start text-left',
      center: 'items-center text-center',
    },
    topPadding: {
      s0: 'pt-s0',
      s4: 'pt-s4',
      s9: 'pt-s9',
    },
  },
})

type HgTextBlockVariants = VariantProps<typeof textWrapper>

export type HgTextBlockProps = {
  title: ReactNode
  body?: string | React.ReactNode
  buttonProps?: HgButtonLinkProps
  titleTag?: TextTagProps['tag']
} & HgTextBlockVariants

const HgTextBlock = ({
  title,
  body,
  titleTag,
  buttonProps,
  alignment = 'center',
  topPadding = 's0',
}: HgTextBlockProps) => {
  return (
    <div className={textWrapper({alignment, topPadding})}>
      <TextTag tag={titleTag} className="text-text-default arcadia-subheading-5">
        {title}
      </TextTag>
      {body && <p className="mt-s2 text-text-subdued arcadia-body-2">{body}</p>}
      {buttonProps && (
        // TODO: convert this to support both `onClick` and `href`
        <HgButtonLink className="mt-s5" variant="frosted" {...buttonProps}>
          {buttonProps.children}
        </HgButtonLink>
      )}
    </div>
  )
}

export default HgTextBlock
