'use client'

import Lightbox, {
  type Render,
  type ControllerRef,
  type Slide,
  type CustomAssetSlide,
} from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import 'yet-another-react-lightbox/plugins/counter.css'
import {HgButton} from '~/design-system/hg/components'
import {useRef} from 'react'
import {type OmitStrict} from 'type-zoo'
import {cn} from '~/design-system/utils'

type HgLightboxProps = {
  index?: number
  isOpen: boolean
  closeLightbox: () => void
  slides: Slide[]
  render?: OmitStrict<Render, 'buttonClose' | 'buttonPrev' | 'buttonNext'>
}

function isCustomAssetSlide(slide: Slide): slide is CustomAssetSlide {
  return slide.type === 'custom-asset'
}

const HgCustomAssetSlide = ({asset, caption, credit}: CustomAssetSlide) => {
  return (
    <div className="h-full w-full">
      <div className="mt-[calc((var(--s3)_*_2_+_42px))] h-[calc(100%_-_(var(--s3)_*_2_+_42px))] max-h-[calc(100%_-_(var(--s3)_*_2_+_42px))]">
        <div className="flex h-full max-h-full w-full flex-col justify-between">
          <div className="flex h-full max-h-full min-h-0 w-full flex-1 items-center justify-center py-s6">
            <div className="flex h-full max-h-full w-full flex-col items-center justify-center px-s5 lg:px-s10">
              <div className="h-fit max-h-full w-[auto] max-w-full space-y-s2 [&_img]:h-full [&_img]:w-auto [&_img]:object-contain">
                {asset}
                {credit && (
                  <p className="text-text-subdued arcadia-body-3">{credit}</p>
                )}
              </div>
            </div>
          </div>
          <div className="my-s3 flex h-fit items-center justify-center px-s5 pb-[calc(var(--s6)+42px)] text-center text-text-default arcadia-body-3 md:min-h-[42px] md:px-[calc((var(--s5)*2)+(var(--s6)*2)+84px)] md:pb-0">
            {caption}
          </div>
        </div>
      </div>
    </div>
  )
}

export const HgLightbox = ({
  index,
  isOpen,
  closeLightbox,
  slides,
  render,
}: HgLightboxProps) => {
  const lightboxRef = useRef<ControllerRef | null>(null)
  const hasSingleSlide = slides.length === 1
  const plugins = hasSingleSlide ? [] : [Counter]

  return (
    <Lightbox
      index={index || 0}
      controller={{ref: lightboxRef}}
      className="text-text-default arcadia-body-3"
      slides={slides}
      open={isOpen}
      close={closeLightbox}
      plugins={[...plugins]}
      render={{
        ...render,
        buttonClose: () => (
          <HgButton
            key="close"
            onClick={() => lightboxRef.current?.close()}
            variant="frosted"
            contentType="icon"
            iconProps={{iconType: 'xmark'}}
            className="mx-[calc(var(--s5)_-_8px)] my-s2"
            aria-label="Close"
          />
        ),
        buttonPrev: () => (
          <HgButton
            key="prev"
            onClick={() => lightboxRef.current?.prev()}
            variant="frosted"
            contentType="icon"
            iconProps={{iconType: 'chevron-left'}}
            aria-label="Previous"
            className={cn(
              'absolute bottom-0 right-0 my-s3 ml-s6 mr-[calc(var(--s5)_+_var(--s2)+_42px)]',
              hasSingleSlide && 'hidden'
            )}
          />
        ),
        buttonNext: () => (
          <HgButton
            key={'next'}
            onClick={() => lightboxRef.current?.next()}
            variant="frosted"
            contentType="icon"
            iconProps={{iconType: 'chevron-right'}}
            aria-label="Next"
            className={cn(
              'absolute bottom-0 right-0 my-s3 mr-s5',
              hasSingleSlide && 'hidden'
            )}
          />
        ),
        slide: ({slide}) =>
          isCustomAssetSlide(slide) ? <HgCustomAssetSlide {...slide} /> : null,
      }}
      styles={{
        container: {backgroundColor: 'var(--background-default)'},
      }}
      counter={{
        separator: '/',
        container: {
          className:
            'arcadia-ui-2 text-text-default p-0 h-40 min-h-40 inline-flex items-center justify-center my-s3 ml-s5 mr-s6',
          style: {
            top: 'unset',
            filter: 'unset',
            bottom: 0,
            color: 'var(--text-default)',
          },
        },
      }}
    />
  )
}
