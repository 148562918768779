import {type ReactNode} from 'react'
import {
  HgIcon,
  HgTextLink,
  type HgIconType,
  type HgTextLinkProps,
} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'

export type HgAsideProps = {
  iconType?: HgIconType
  title?: ReactNode
  subhead: ReactNode
  linkProps?: Omit<HgTextLinkProps, 'variant'>
  className?: string
}

export type HgArticleWYSIWYGAsideProps = HgAsideProps & {
  accompanyingBlock?: ReactNode
}

export const HgAside = ({
  iconType,
  title,
  subhead,
  linkProps,
  className,
}: HgAsideProps) => {
  return (
    <aside className={cn(className, 'flex flex-col')}>
      <span className="inline-flex items-center text-text-default">
        {iconType && <HgIcon iconType={iconType} size="small" className="me-s2" />}
        {title && <p className="arcadia-heading-9">{title}</p>}
      </span>
      <p className="text-text-subdued arcadia-body-3">{subhead}</p>
      {linkProps?.href && (
        <HgTextLink
          {...linkProps}
          className="mt-s3 arcadia-ui-2"
          variant="underline"
        />
      )}
    </aside>
  )
}

export const HgArticleWYSIWYGAside = (props: HgArticleWYSIWYGAsideProps) => {
  return (
    <>
      {props.accompanyingBlock}
      <HgAside
        {...props}
        className="col-span-full border-y border-solid border-border-subdued py-s4 md:col-span-8 md:col-start-3 lg:col-span-4 lg:col-start-13 lg:border-0 lg:py-0"
      />
    </>
  )
}
