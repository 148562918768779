import {HgIcon, HgLoadingDots} from '~/design-system/hg/components'
import {
  type LabelButtonIconProps,
  type ButtonIconProps,
  type HgButtonVariants,
} from '../types'
import {hgButtonVariants} from '../variants'
import HgButtonFluidBackground from './HgButtonFluidBackground'

type ButtonContentProps = {
  iconProps?: LabelButtonIconProps | ButtonIconProps
  children?: React.ReactNode
  uiState: HgButtonVariants['uiState']
  variant: HgButtonVariants['variant']
  size?: HgButtonVariants['size']
  contentType?: HgButtonVariants['contentType']
}

const HgButtonContent = ({
  iconProps,
  children,
  uiState,
  variant,
  size = 'medium',
  contentType = 'label',
}: ButtonContentProps) => {
  const {content} = hgButtonVariants({variant})

  // Handle loading state
  if (uiState === 'pending') {
    return iconProps ? (
      <HgIcon className={content()} size={size} iconType="loading" />
    ) : (
      <HgLoadingDots className={content()} />
    )
  }

  // Handle icon-only buttons
  if (contentType === 'icon' && iconProps) {
    return <HgIcon className={content()} size={size} iconType={iconProps.iconType} />
  }

  // Handle label buttons
  return (
    <>
      {iconProps && 'position' in iconProps && iconProps.position === 'left' && (
        <HgIcon
          size={size}
          iconType={iconProps.iconType}
          className={content({position: 'left'})}
        />
      )}
      {children}
      {iconProps && 'position' in iconProps && iconProps.position === 'right' && (
        <HgIcon
          size={size}
          iconType={iconProps.iconType}
          className={content({position: 'right'})}
        />
      )}
    </>
  )
}

export default HgButtonContent

export const PrimaryButtonWrapper = ({children}: {children: React.ReactNode}) => (
  <>
    <HgButtonFluidBackground />
    <div className="align-center pointer-events-none relative z-[1] flex max-h-fit max-w-fit text-nowrap">
      {children}
    </div>
  </>
)
