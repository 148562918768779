'use client'

import {useTexture} from '@react-three/drei'
import {StableFluidBackground} from '~/design-system/hg/components'
import {purple} from '~/design-system/hg/tokens/colors/colors'

// ensure the button background map is available when the page loads
useTexture.preload('/design-system/hg/button-stable-fluid-gradient-bg.png')

function HgButtonFluidBackground() {
  return (
    <div className="absolute inset-[-2px] z-0 hidden items-center justify-center opacity-0 transition-opacity duration-500 group-hover:opacity-100 group-active:opacity-75 group-aria-busy:opacity-0 group-aria-disabled:opacity-0 sm:block">
      <StableFluidBackground
        cursorSize={39}
        mouseForce={20}
        poissonIterations={18}
        resolution={0.9}
        gradientMapPath="/design-system/hg/button-stable-fluid-gradient-bg.png"
        backgroundColorHex={purple.magic[700]}
      />
    </div>
  )
}

export default HgButtonFluidBackground
